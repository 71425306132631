<template>
    <div class="subscribe-main">
        <top-nav></top-nav>

        <header-guide></header-guide>

        <bread-crumb></bread-crumb>

        <div class="article-detail">
            <div class="container">
                <div class="contarea-panel article_content">
                    <div class="details-cont">
                        <div class="detail-hd">
                            <h3 class="title">
                                <i
                                    :class="{'jk-icon-huo':article.is_hot === 1}"
                                    style="color:red;font-size:24px;"
                                ></i>
                                {{article.title}}
                            </h3>

                            <div class="type">
                                <span
                                    v-if="article.is_jh==1"
                                    class="jinghua"
                                >精华</span>
                                <span
                                    v-for="(item,index) in position"
                                    :key="index"
                                    v-text="item"
                                ></span>
                            </div>

                            <div class="guide">
                                <div class="author">
                                    <span class="avatar">
                                        <img :src="util.reImg(author.img)" />
                                    </span>
                                    <strong v-text="author.name"></strong>
                                </div>

                                <div class="count">
                                    <span>
                                        <i class="jk-icon-liulanliang"></i>
                                        {{article.view_count}}
                                    </span>
                                    <span>
                                        <i class="jk-icon-dianzanliang"></i>
                                        {{like_count}}
                                    </span>

                                    <span>
                                        <i class="jk-icon-weishoucang1"></i>
                                        {{keep_count}}
                                    </span>
                                    <span>{{article.create_time}} 发布</span>
                                </div>
                            </div>
                        </div>

                        <div class="detail-bd">
                            <div v-html="article.content"></div>
                        </div>

                        <div class="detail-ftr">
                            <div
                                class="annex-wrap"
                                v-if="article.annex_count>0"
                            >
                                <h4 v-if="isPay==0">
                                    项目附件：
                                    <span v-if="article.download_point_count>0||article.download_price>0">
                                        <span>下载此附件</span>
                                        <span v-if="article.download_point_count>0">需要支付{{article.download_point_count}}点券</span>
                                        <span v-if="article.download_price>0">&nbsp;需要支付{{article.download_price}}元</span>
                                    </span>
                                    <span v-else>
                                        <el-tooltip
                                            content="请在下方下载所需附件"
                                            placement="top"
                                            effect="light"
                                            v-if="isLogin"
                                        >
                                            <el-button>免费下载</el-button>
                                        </el-tooltip>
                                        <el-tooltip
                                            content="请登陆后下载"
                                            placement="top"
                                            effect="light"
                                            v-else
                                        >
                                            <el-button>免费下载</el-button>
                                        </el-tooltip>
                                    </span>
                                </h4>

                                <h4 v-else>
                                    <span>您已支付，点击以下附件链接进行下载</span>
                                </h4>

                                <div class="annex-cont">
                                    <span v-if="isPay==0 && (article.download_point_count!=0 || article.download_price!=0)">共有{{article.annex_count}}个附件，支付后可查看下载</span>

                                    <ul
                                        class="annex-items"
                                        v-if="annex"
                                    >
                                        <li
                                            v-for="(item,index) in annex"
                                            :key="index"
                                        >
                                            <a
                                                :href="getDownloadUrl(item.path)"
                                                download
                                                target="_bank"
                                                style="color:#4d72d7;"
                                            >
                                                【附件{{index+1}}】{{item.name}}
                                                <span style="font-size:12px;color:#646464;">&nbsp;&nbsp;&nbsp;{{item.size}}</span>
                                                <span style="margin-left:30px;">下载</span>
                                            </a>
                                        </li>
                                    </ul>

                                    <div class="downloag-tips">
                                        <span v-if="(article.download_point_count>0 || article.download_price>0) && isPay==0">
                                            <span v-if="article.download_point_count>0 ">
                                                <strong>{{article.download_point_count}}</strong>
                                                <span style="font-size:14px">点券</span>
                                            </span>
                                            <br />
                                            <span
                                                style="font-size:14px"
                                                v-if="article.download_price>0"
                                            >
                                                <strong>{{article.download_price}}</strong>
                                                <span>元</span>
                                            </span>
                                        </span>

                                        <el-button
                                            @click="openPayDialog"
                                            v-if="(article.download_point_count>0 || article.download_price>0) && isPay==0"
                                        >支付并下载</el-button>
                                    </div>
                                </div>
                            </div>

                            <div class="detail-btn">
                                <el-button
                                    :class="{'active': is_like==1}"
                                    @click="addArticleLike(article.id)"
                                >
                                    <i class="jk-icon-weidianzan"></i>
                                    点赞 {{like_count}}
                                </el-button>

                                <!--点赞时添加active-->
                                <el-button
                                    @click="addArticleKeep(article.id)"
                                    :class="{'active':is_keep==1}"
                                >
                                    <i class="jk-icon-weishoucang1"></i>
                                    收藏 {{keep_count}}
                                </el-button>

                                <!--收藏时添加active 文字更改为已收藏 样式变化-->
                                <el-button @click="shareDialog = true">
                                    <i class="jk-icon-fenxiang"></i>分享
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sidebar-panel">
                    <div
                        class="author-panel article-hd"
                        v-if="author && author.uk"
                    >
                        <span class="avatar">
                            <img :src="util.reImg(author.img)" />
                        </span>

                        <h4
                            class="name"
                            v-text="author.name"
                        ></h4>

                        <strong
                            :class="subscribeStatus"
                            @click="keep(author.uk)"
                            v-text="subscribeText"
                        ></strong>

                        <ul class="count">
                            <li>
                                <strong>{{subscribe_count}}人</strong>
                                <p>订阅人数</p>
                            </li>

                            <li>
                                <strong>{{author.article_count}}篇</strong>
                                <p>文章</p>
                            </li>
                        </ul>
                    </div>

                    <sidebar-panel
                        :news="news"
                        :dataShare="dataShare"
                    ></sidebar-panel>
                </div>
            </div>
        </div>

        <global-footer></global-footer>

        <el-dialog
            title="输入支付密码"
            :visible="passwordDialog"
            width="30%"
            @close="closePasswordDialog"
            close-on-click-modal
            lock-scroll
        >
            <el-form
                :model="form"
                class="password-form"
            >
                <el-form-item>
                    <el-input
                        type="password"
                        v-model="password"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="sendPay"
                    >确定支付</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--支付二维码-->
        <el-dialog
            class="blue-top-border"
            title="支付二维码"
            :visible="payQrDialog"
            width="500px"
            center
            @close="closeQrDialog"
        >
            <div class="pointCoupon-buy">
                <p>支付金额</p>
                <p class="price">
                    ￥
                    <strong>{{price}}</strong>（本次）
                </p>
                <div class="buy-ewm">
                    <img :src="util.reImg(QRImgUrl)" />
                </div>
                <div class="guide">
                    请使用{{payTypeText}}扫描
                    <br />二维码完成支付
                </div>
            </div>
        </el-dialog>

        <el-dialog
            v-loading="loading"
            class="blue-top-border buy-dialog"
            title="下载支付"
            :visible.sync="buyDialog"
            width="745px"
            center
        >
            <p class="tips">支付可以下载本文所有附件</p>
            <h5>选择支付方式</h5>
            <el-radio-group
                v-model="radio"
                class="buy-dialog-radio-group"
            >
                <el-radio
                    :label="1"
                    v-if="article.download_point_count>0"
                >
                    <div class="buy-group">
                        <span>
                            <strong>{{article.download_point_count}}</strong>点券
                        </span>
                        <p>点券支付</p>
                    </div>
                </el-radio>
                <el-radio
                    :label="2"
                    v-if="article.download_price>0"
                >
                    <div class="buy-group">
                        <span>
                            ￥
                            <strong>{{article.download_price}}</strong>元
                        </span>
                        <p>余额支付</p>
                    </div>
                </el-radio>
                <el-radio
                    :label="3"
                    v-if="article.download_price>0"
                >
                    <div class="buy-group">
                        <span>
                            ￥
                            <strong>{{article.download_price}}</strong>元
                        </span>
                        <p>
                            <i class="jk-icon-weixinzhifu"></i>微信支付
                        </p>
                    </div>
                </el-radio>
                <el-radio
                    :label="4"
                    v-if="article.download_price>0"
                >
                    <div class="buy-group">
                        <span>
                            ￥
                            <strong>{{article.download_price}}</strong>元
                        </span>
                        <p>
                            <i class="jk-icon-zhifubaozhifu"></i>支付宝支付
                        </p>
                    </div>
                </el-radio>
            </el-radio-group>
            <h6 v-if="article.download_point_count>0">
                我的点券余额：
                <span>{{userInfo.info.points_surplus}}点</span>
                <strong @click="chong">充值</strong>
            </h6>
            <h6 v-if="article.download_price>0">
                我的余额：
                <span>{{userInfo.info.surplus}}元</span>
                <strong @click="chong">充值</strong>
            </h6>
            <el-checkbox v-model="checked">
                我已阅读并同意
                <a
                    href="/article/detail/33"
                    target="_bank"
                >《用户支付条例》</a>
            </el-checkbox>
            <el-button @click="createOrder">支付并下载</el-button>
        </el-dialog>

        <!--文章分享-->
        <el-dialog
            class="blue-top-border"
            title="分享"
            :visible.sync="shareDialog"
            width="390px"
            center
        >
            <ul class="share-items">
                <li class="item">
                    <span>
                        <img :src="util.reImg(getWebsiteDatas.site_wechat)" />
                    </span>
                    <p>分享至微信好友</p>
                </li>
                <li class="item">
                    <span>
                        <img :src="util.reImg(qqCodeImg)" />
                    </span>
                    <p>分享至QQ好友</p>
                </li>
                <li class="item">
                    <a
                        :href="weiboShareUrl"
                        target="_bank"
                    >
                        <span>
                            <img :src="$img.jk_icon_fenxiangweibo" />
                        </span>
                        <p>微博</p>
                    </a>
                </li>
                <li class="item">
                    <a
                        :href="qqShareUrl"
                        target="_bank"
                    >
                        <span>
                            <img :src="$img.jk_icon_fenxiangqq" />
                        </span>
                        <p>QQ好友</p>
                    </a>
                </li>
                <li class="item">
                    <a
                        :href="qzoneShareUrl"
                        target="_bank"
                    >
                        <span>
                            <img :src="$img.jk_icon_fenxiangqqkongjian" />
                        </span>
                        <p>QQ空间</p>
                    </a>
                </li>
            </ul>
        </el-dialog>
    </div>
</template>

<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
import SidebarPanel from "@/components/common/SidebarPanel.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import "quill/dist/quill.snow.css";

export default {
    name: "articleDetail",
    metaInfo() {
        return {
            title: this.article_title + " - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
                }, {
                    name: "description",
                    content: this.article_content
                }
            ]
        }
    },
    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        GlobalFooter, //公共底部
        SidebarPanel,//右侧栏
    },
    data() {
        return {
            radio: 1, //支付方式
            buyDialog: false,
            shareDialog: false,
            checked: false,
            article: [],
            is_like: 0,
            is_keep: 0,
            like_count: 0,
            keep_count: 0,
            qqShareUrl: "",
            weiboShareUrl: "",
            qzoneShareUrl: "",
            annex: [],
            isPay: 0,
            qqCodeImg: "http://qr.topscan.com/api.php?&w=300&text=" + window.location.href,
            like_count: 0,
            loading: false,
            position: [],
            uk: "",
            password: null,
            passwordDialog: false,
            orderNo: null,
            form: null,
            payQrDialog: false, //付款二维码弹出对话框
            QRImgUrl: "", //付款二维码地址
            price: "", //付款二维码金额
            payTypeText: "", //付款二维码文字
            timer: "",
            author: '',//作者
            subscribeStatus: "status",
            subscribeText: "关注",
            subscribe_count: 0,
            news: '',
            dataShare: '',
            article_title: '',//seo文章标题
            article_content: '',//seo文章内容
            isLogin: false,
        };
    },
    computed: {
        ...mapGetters("websitInfo", ["getWebsiteDatas"]),
        ...mapState(["userInfo"])
    },
    created() {
        this.loadListData();//获取文章详情数据
        this.getAnnex();//获取文章附件数据
        this.loadHotData();
        this.loadAuthorsData();
    },
    methods: {
        //文章详情
        loadListData() {
            let _this = this;
            _this.post(
                "/article/index/detail",
                {
                    id: _this.$route.params.id
                },
                function (res) {
                    if (res.code == 200) {
                        //seo - 文章head部分信息
                        _this.article_title = res.data.title;
                        _this.article_content = res.data.content.replace(/<[^>].*?>/g, "");//截取文章内容，去除html标签

                        _this.article = res.data;
                        _this.is_like = res.data.is_like;
                        _this.is_keep = res.data.is_keep;
                        _this.like_count = res.data.like_count;
                        _this.keep_count = res.data.keep_count;
                        _this.author = res.data.user;
                        _this.uk = res.data.user.uk;
                        _this.position = res.data.position_arr;
                        _this.subscribe_count = res.data.user.subscribe_count;
                        _this.qqShareUrl = "https://connect.qq.com/widget/shareqq/index.html?url=" + window.location.href + "&pics=&site=" + window.location.href;
                        _this.weiboShareUrl = "http://service.weibo.com/share/share.php?appkey=583395093&title=" + res.data.title + "&url=" + window.location.href + "&source=bshare&retcode=0&ralateUid=";
                        _this.qzoneShareUrl = "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" + window.location.href + "&title=" + res.data.title + "&pics=&summary=" + res.data.description;

                        if (res.data.is_subscribe == 1) {
                            _this.subscribeStatus = "activestatus";
                            _this.subscribeText = "已关注";
                        } else {
                            _this.subscribeStatus = "status";
                            _this.subscribeText = "关注";
                        }
                        if (res.msg == 'success') {
                            _this.isLogin = true;
                        }
                    } else {
                        _this.$message.error('获取失败');
                    }
                }
            );
        },

        //加载热门文章列表数据
        loadHotData() {
            let _this = this;
            this.post("/article/index/hot", {}, function (res) {
                if (res.code == 200) {
                    _this.news = res.data;
                }
            });
        },

        //加载人心分享者列表数据
        loadAuthorsData() {
            let _this = this;
            _this.post("/article/index/authors", {}, function (res) {
                if (res.code == 200) {
                    _this.dataShare = res.data;
                }
            });
        },

        //收藏文章
        addArticleKeep(e) {
            let _this = this;
            this.post(
                "/article/article/article_keep",
                {
                    id: e
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data.keep == 1) {
                            _this.is_keep = 1;
                            _this.keep_count = _this.keep_count + 1;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        } else {
                            _this.is_keep = 0;
                            _this.keep_count = _this.keep_count - 1;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //点赞
        addArticleLike(e) {
            let _this = this;
            this.post(
                "/article/article/article_like",
                {
                    id: e
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data.like == 1) {
                            _this.is_like = 1;
                            _this.like_count = _this.like_count + 1;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        } else {
                            _this.is_like = 0;
                            _this.like_count = _this.like_count - 1;
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //关注用户
        keep(uk) {
            let _this = this;
            this.post(
                "/user/user/collect_user",
                {
                    uk: uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data == 1) {
                            //关注成功
                            _this.subscribe_count = _this.subscribe_count + 1;
                            _this.subscribeStatus = "activestatus";
                            _this.subscribeText = "已关注";
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        } else {
                            //取消关注
                            _this.subscribe_count = _this.subscribe_count - 1;
                            _this.subscribeStatus = "status";
                            _this.subscribeText = "关注";
                            _this.$message({
                                message: res.msg,
                                type: "success"
                            });
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //获取附件
        getAnnex() {
            let _this = this;
            _this.post(
                "/article/index/annex",
                { id: this.$route.params.id },
                function (res) {
                    if (res.code == 200) {
                        _this.annex = res.data.annex ? res.data.annex : [];
                        _this.isPay = res.data.is_pay;
                    }
                }
            );
        },

        //热门文章 - 更多按钮
        goMoreArticle() {
            this.$router.push("/article/index?hot=1");
        },

        //热门文章详情链接
        goDetial(e) {
            this.$router.push("/article/detail/" + e);
        },

        //热门分享者点击链接
        geArticleCenter(e) {
            this.$router.push("/firm/article?geek_id=" + e);
        },

        //获取附件下载地址
        getDownloadUrl(e) {
            return this.websiteConfigs.sourceUrl + e;
        },

        //充值跳转
        chong() {
            //存储当前值
            sessionStorage.setItem('currentPageUrl', document.location.pathname + document.location.search);
            this.$router.push("/user/wallet/list?buy_coupon=1");
        },

        //支付下载
        createOrder() {
            let _this = this;
            let payType = 0;
            let payPrice = _this.article.download_price;

            if (!_this.checked) {
                _this.$message.error("同意《用户支付条例》后才可以支付");
                return false;
            }

            switch (_this.radio) {
                case 1:
                    if (!_this.userInfo.info.has_pay_password) {
                        this.$confirm('不能进行此操作，您还没有设置支付密码，是否现在设置？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                        }).then(() => {
                            //存储当前值
                            sessionStorage.setItem("currentPageUrl", document.location.pathname + document.location.search);
                            _this.$router.push("/user/validpassword?type=1&routerName=userSettingPassword&title=设置支付密码");
                        }).catch(() => { });
                        return false;
                    }

                    payType = "D01"; //点券支付
                    payPrice = _this.article.download_point_count;
                    break;
                case 2:
                    if (!_this.userInfo.info.has_pay_password) {
                        this.$confirm('不能进行此操作，您还没有设置支付密码，是否现在设置？', '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                        }).then(() => {
                            //存储当前值
                            sessionStorage.setItem("currentPageUrl", document.location.pathname + document.location.search);
                            _this.$router.push("/user/validpassword?type=1&routerName=userSettingPassword&title=设置支付密码");
                        }).catch(() => { });
                        return false;
                    }

                    payType = "Y01"; //余额支付
                    break;
                case 3:
                    payType = "W01"; //微信支付
                    break;
                case 4:
                    payType = "A01"; //支付宝支付
                    break;
                default:
                    _this.$message.error("请选择支付方式");
                    return;
                    break;
            }
            //创建订单
            _this.post(
                "/order/order/add",
                {
                    cate: 6, //订单类型 - 下载文件
                    title: _this.article.title + " - 附件下载",
                    price: payPrice, //支付价格
                    pay_type: payType, //支付方式
                    remark: _this.article.id //文章id
                },
                function (res) {
                    _this.loading = true;
                    if (res.code == 200) {
                        _this.orderNo = res.data.order_no;
                        _this.pay(); //付款
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //打开支付弹出框
        openPayDialog() {
            let _this = this;
            if (_this.userInfo.isLogin == true) {
                this.buyDialog = true;
            } else {
                _this.$message.error("请先登录后再操作");
                //存储当前值
                sessionStorage.setItem("currentPageUrl", document.location.pathname + document.location.search);
                setTimeout(() => { _this.$router.push("/login/index?type=login"); }, 1000);
            }
        },

        //付款
        pay() {
            let _this = this;
            let payText = "";
            if (_this.radio == 1 || _this.radio == 2) {
                _this.loading = false;
                _this.passwordDialog = true;//弹出支付密码
            } else if (_this.radio == 3) {
                payText = "微信";
                _this.sendPay(payText);
            } else if (_this.radio == 4) {
                payText = "支付宝";
                _this.sendPay(payText);
            }
        },

        //在线扫码发送支付
        sendPay(payText = "") {
            let _this = this;
            _this.post(
                "/pay/pay/pay",
                {
                    order_no: _this.orderNo,
                    password: _this.password
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        if (_this.radio == 1 || _this.radio == 2) {
                            //点券支付或者余额支付
                            _this.buyDialog = false;
                            _this.passwordDialog = false;
                            _this.$message({
                                message: "恭喜你，支付成功！",
                                type: "success"
                            });
                            _this.getAnnex(); //重新获取附件列表
                        } else {
                            //在线支付
                            _this.buyDialog = false; //关闭支付选择对话框
                            _this.payQrDialog = true; //打开二维码支付对话框
                            _this.price = res.data.price; //支付价格
                            _this.QRImgUrl = res.data.img; //支付二维码
                            _this.payTypeText = payText; //付款二维码文字
                            _this.timer = setInterval(() => {
                                _this.isPayCheck();
                            }, 2000);
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //是否支付成功
        isPayCheck() {
            let _this = this;
            this.post(
                "/pay/index/is_pay",
                {
                    order_no: _this.orderNo
                },
                function (res) {
                    if (res.code == 200) {
                        _this.$message.success("恭喜你，支付成功！");
                        _this.closeQrDialog(); //关闭二维码对话框
                        _this.getAnnex(); //重新获取附件列表
                    }
                }
            );
        },

        //关闭支付密码对话框
        closePasswordDialog() {
            this.passwordDialog = false;
            clearInterval(this.timer);
        },

        //关闭支付二维码对话框
        closeQrDialog() {
            this.payQrDialog = false;
            clearInterval(this.timer);
        },

    },
    watch: {
        $route() {
            this.loadListData();
        }
    }
};
</script>

<style lang="less" scoped>
@import "../../styles/subscribe.less";
.password-form .el-form-item__content {
    text-align: center;
    margin-top: 20px;
}

.article-detail {
    .contarea-panel {
        display: block;
        float: left;
        width: 940px;
        margin-bottom: 30px;
        overflow: hidden;
        .details-cont {
            padding-bottom: 20px;
        }
    }

    .sidebar-panel {
        float: right;
    }
}
</style>


